<template>
  <div class="homeViewByCourse">
    <div>
      <!-- CAROUSEL VIEW -->
      <div class="carouselView__grid">
        <slot name="carousel" :data="data"></slot>
      </div>
      <!-- CATEGORY VIEW -->
      <div class="categoryMode__grid">
        <slot name="normal" :data="data"></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.categoryMode {
  &__title {
    @extend .body-semibold-20;
    color: var(--fontcolor);
    margin-bottom: var(--spacing-16);
  }
}
</style>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
    },
    controlRef: {
      type: String,
    },
    data: {
      type: [Object, Array],
      default: () => [{}],
    },
    movieCoverMode: {
      type: Boolean,
    },
    hideDisabledCourses: {
      type: Boolean,
    },
  },
};
</script>
